import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";

/**
 * 收藏/取消收藏
 */
/**
 */
 export async function addFavoriteData(params) {
  return request(
    addurl("AddFavoriteData", requestApiUrl.requestFavoritedata),
    METHOD.POST,params
  );
}
/**
 * 浏览记录
 */
/**
 */
 export async function addLog(params) {
   request(
    addurl("AddLog", requestApiUrl.requestLog),
    METHOD.POST,params
  );
}
/**
 * 查询是否已收藏
 */
/**
 */
 export async function getIsFav(params) {
  return request(
    addurl("getIsFav", requestApiUrl.requestFavoritedata) + '/' + params.username  + '/' + params.doi + '/' + params.restype,
    METHOD.get
  );
}
/**
 * 获取单个图书目录
 * @param id //图书id
 */
 export async function getCatalogByid(params) {
  return request(
    addurl("getCatalogByid", requestApiUrl.requestApiChapter) +
      "/" +
      params.id,
    METHOD.GET,
  );
}

/**
 * 获取单个图书
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param order 排序字段
 */
 export async function getBookDetails(params) {
  return request(
    addurl("getInfo", requestApiUrl.requestBook) +
      "/" +
      params.doi,
    METHOD.GET,
  );
}
/**
 * 获取二维码
 * @param url 链接地址
 * @param type 资源类型
 * @param doi 资源doi
 * @returns 
 */
 export async function getQRCode(params) {
  return request(
    addurl("getQRByUrl", requestApiUrl.requestGetUtilsApi),
    METHOD.GET,params
  );
}


/**
 * 获取视频列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param order 排序字段
 */
 export async function getvideoList(params) {
  return request(
    addurl("getList", requestApiUrl.requestVideo) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      online: "1",
      order: "SYS_FLD_HITCOUNT",
      type: "p"
    }
  );
}
/**
 * 获取期刊列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param order 排序字段
 */
 export async function getjournalList(params) {
  return request(
    addurl("getList", requestApiUrl.requestJdrpJournal) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      type: params.type
    }
  );
}

/**
 * 获取图书列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param order 排序字段
 */
 export async function getBookList(params) {
  return request(
    addurl("getList", requestApiUrl.requestBook) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      online: "1",
      order: "SYS_FLD_HITCOUNT",
    }
  );
}

/**
 * 获取广告列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 */
export async function getAdList(params) {
  return request(
    addurl("getList", requestApiUrl.requestAd) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      type: params.type,
    }
  );
}
/**
 * 获取广告列表
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param type p-获取部分字段
 */
 export async function getAdvertisingList(params) {
  return request(
    addurl("getList", requestApiUrl.requestNews) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      online: "1",
    }
  );
}

/**
 * 获取推荐列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 */
export async function getRecommendList(params) {
  return request(
    addurl("getRecommendResourcesList", requestApiUrl.requestRecommend) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      type: params.typeDoi,
      tag: params.tag,
    }
  );
}

/**
 * 获取新闻列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getNewsList(params) {
  return request(
    addurl("getList", requestApiUrl.requestNews) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      query: params.Keyword,
      online: 1,
      typeid: params.typeid,
    }
  );
}

/**
 * 获取列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getAudioList(params) {
  return request(
    addurl("getList", requestApiUrl.requestAudio) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      query: params.Keyword,
      order: params.order,
      online: 1,
    }
  );
}

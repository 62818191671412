import { baseURL } from "./proxyconfig";

export const adDoiList = {
  indexDOi: "e5c3f04c-6abd-49c4-b95c-df58eae871f6",
  newsIndexDoi: "ec59a349-6d52-441c-9d05-4033794efcb9",
  bookIndexDoi: "bfdfd9f7-1719-41fa-ad70-c2cbeba07e5a",
  userCenterIndexDoi: "1a395a22-66eb-4171-843b-16a47dece65e",
  activitiesIndexDoi:'62395a22-66eb-4171-843b-16a47dece662',//咨询活动页广告图
  indexMidDoi:"665d3a5f-27dd-4565-86fe-568358da8f31"
};

export const recommendDoiList = {
  indexBookDoi: "548c6b27-8626-4027-a300-914a6eb16c43", //首页-图书推荐
  detailBookDoi: "666c6b27-8626-4027-a300-914a6eb16c43", //图书详情-图书推荐
  indexNewsDoi: "47981575-c81c-4ab5-bbb5-73dfcdeca8cc", //首页-新闻推荐
  indexPicDoi: "3308af49-9619-4850-b3b8-c6fa526c8e38", //首页-热门图片
  indexVideoDoi: "39a447ca-ea5c-4bf1-bd71-c227d4f97521", //首页-视频推荐
  indexJournalDoi: "ec3e3227-b64e-41ac-8933-c4a855b14356", //首页-期刊年期
  indexPaperDoi: "0759116d-fcd3-4ab8-846a-742167b94298", //首页-报纸年期
  indexJournalArticleDoi: "acde3590-304e-4399-8755-e35430b1338e", //首页-期刊文章
  indexPaperArticleDoi: "fcb337a4-03c8-4e44-96bf-fb5b7c33e9bb", //首页-报纸文章
  indexAudioDoi: "93ff9233-5074-47c3-ab8a-fcb9c1496174", //首页-音频
  jJournalRecommendDoi: "75955199-ca73-49af-9d26-63a2e266cd0d", //期刊中心页-主编推荐
  jJournalDetailDoi: "12c499c8-12f9-42f6-bffb-56fcb02e699e", //期刊中心页-期刊简介
  jJournalRArticle: "f493d3f7-0785-45b0-9d79-c7cffc9eeeb5", //期刊中心页-精彩推荐
  pPaperRecommendDoi: "c00c7c42-7587-4da3-8d32-27c9ee739571", //报纸中心页-主编推荐
  pPaperDetailDoi: "9b743f9d-ff08-4b34-bbc2-a38f3350cbcf", //报纸中心页-报纸简介
  pPaperArticle: "c782acdb-1201-4d19-ad85-d62a7598a2aa", //报纸中心页-精彩推荐
  detailVideoDoi: "62ac6b27-8626-4027-a300-914a6eb16c43", //视频详情-推荐
  detailAudioDoi: "1fada259-9f8e-4fb5-b8ed-81f13458e9fd", //音频详情-推荐
};

export const requestApiUrl = {
  requestAd: baseURL + "/api/ad/", //广告图接口
  requestFavoritedata: baseURL + "/api/favoritedata/", //收藏接口 //查询收藏列表接口
  requestLog: baseURL + "/api/log/", //浏览记录列表
  requestAboutUs: baseURL + "/api/aboutus/", //关于我们
  requestRecommend: baseURL + "/api/resourcelist/", //推荐接口
  requestJdrpJournal: baseURL + "/api/jdrpJournal/", //期刊查询和排行榜
  requestJYear: baseURL + "/api/journalyear/", //期刊年份&年期管理
  requestPYear: baseURL + "/api/newspaperyear/", //报纸年份&年期管理
  requestAttachment: baseURL + "/api/attachment/", //附件
  requestApiNews: baseURL + "/api/news/", //前台新闻接口
  requestApiChapter: baseURL + "/api/chapter/", //根据图书id获取目录接口
  requestAccount: baseURL + "/account/",
  requestBook: baseURL + "/book/", //图书
  requestNews: baseURL + "/news/", //新闻
  requestPicture: baseURL + "/picture/", //图片
  requestTheme: baseURL + "/theme/", //分类
  requestVideo: baseURL + "/video/", //视频
  requestAudio: baseURL + "/audio/", //音频
  requestLdb: baseURL + "/ldb/", //逻辑库
  requestJournal: baseURL + "/journal/", //期刊
  requestJournalYear: baseURL + "/journalyear/", //期刊年期
  requestJournalArticle: baseURL + "/journalarticle/", //期刊文章
  requestPaper: baseURL + "/newspaperinfo/", //报纸
  requestPaperYear: baseURL + "/newspaperyearinfo/", //报纸年期
  requestPaperArticle: baseURL + "/newspaperarticle/", //报纸文章
  requestGetPdfInfo: baseURL + "/showpdf/", //PDF数据信息
  requestGetUtilsApi: baseURL + "/api/utils/", //前台接口管理-工具接口
  requestGetUserInfo: baseURL + "/user/",
};

export default {
  adDoiList,
  recommendDoiList,
  requestApiUrl,
};
